<template>
  <base-section id="customer-stories">

    <v-container>
      <v-row>
        <v-col
          v-for="c of cases"
          :key="c.title"
          cols="12"
          md="4"
        >
          <base-case-card v-bind="c" />
        </v-col>
      </v-row>
    </v-container>

  </base-section>

</template>

<script>

  import axios from 'axios';
  import BaseCaseCard from '@/components/base/CaseCard'

  export default {
    name: 'SectionCustomerStories',
    components: {
      BaseCaseCard,
    },
    data: () => ({
      cases: [],
      isLoading: false,
    }),
    watch:{
      // eslint-disable-next-line no-unused-vars
      $route (to, from){
        this.loadData();
      }
    },
    created() {
      this.loadData();
    },
    methods: {
      loadData() {
        this.isLoading = true;
        axios.get(`https://strapi.ethosevaluate.net/api/posts?fields[0]=Title&fields[1]=Slug&fields[2]=Lead&filters[Type]=case&sort[0]=Date:desc&pagination[page]=1&pagination[pageSize]=3&populate=Image&locale=${this.$i18n.locale}`).then(res => {
          this.cases = Array.isArray(res.data?.data) ? res.data.data.map(post => ({
            lang: this.$i18n.locale,
            title: post.attributes?.Title,
            lead: post.attributes?.Lead,
            image: post.attributes?.Image?.data?.attributes?.formats?.small?.url,
            slug: post.attributes?.Slug,
            id: post.id,
          })) : [];
        }).catch(err => {
          console.error(err);
        }).finally(() => {
          this.isLoading = false;
          window.prerenderReady = true;
        });
      },
    },
  }
</script>
